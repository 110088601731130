import {usePage} from "@inertiajs/vue3";

export default {
    methods: {
        userCan: function (permission) {
            return this.$page.props.auth.user.permissions.includes(permission) || this.$page.props.auth.user.admin;
        }
    }
}

export function useUserCan() {
    const page = usePage();

    const userCan = (permission) => {
        return page.props.auth.user.permissions.includes(permission) || page.props.auth.user.admin;
    }

    return {
        userCan,
    }
}
