import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
    components,
    directives,
    defaults: {
        global: {},
        VTextField: {
            variant: 'underlined'
        },
        VSelect: {
            variant: 'underlined'
        },
        VAutocomplete: {
            variant: 'underlined'
        }
    },
    theme: {
        defaultTheme: 'light',
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                dark: false,
                colors: {
                    primary: '#FF9100',
                    admin_primary: '#78c383',
                    secondary: '#666666',
                    tertiary: '#93a2bc',
                    accent: '#FF9100',
                    system: '#3b3a51',
                    system_background: '#f8f8f6',
                    dataset_background: '#dfdfe3',
                    onsite_primary: '#0F81CA',
                    onsite_footer_background: '#DFDFE3',
                    "on-primary": '#FFF',
                    "on-admin_primary": '#FFF',
                }
            },
            dark: {
                dark: true,
                colors: {
                    primary: '#FF9100',
                    admin_primary: '#78c383',
                    secondary: '#666666',
                    tertiary: '#93a2bc',
                    accent: '#FF9100',
                    system: '#3b3a51',
                    system_background: '#424242',
                    dataset_background: '#424242',
                    onsite_primary: '#0F81CA',
                    onsite_footer_background: '#2F2F2F',
                },
            }
        }
    }
})

export default vuetify
